import { createI18n } from 'vue-i18n'

/**
 * Internationalization
 */
export default createI18n({
  locale: localStorage.getItem('locale') || 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en: {
      Save: 'Save',
      Saved: 'Saved'
    },
    nl: {
      Save: 'Opslaan',
      Saved: 'Opgeslagen'
    }
  },
  legacy: false
})
