import { getAuthCookie } from '~/utils/auth-cookie'
import moment from 'moment'
import type { AuthHeader, Gender, RelationshipStatus, TaskStatus } from '~/ts/globals'
import { TASK_STATUS, ACTION_LIST_STATUS, LanguageEnum } from '~/ts/enums'

export const APP_NAME: string = import.meta.env.VITE_APP_NAME || 'Ainsights'
export const API_URL: string = import.meta.env.VITE_API_URL
export const BROADCAST_URL: string = import.meta.env.VITE_BROADCAST_URL

export const AUTH_HEADER: AuthHeader = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAuthCookie()}`,
  Locale: window.localStorage.getItem('lang') || 'en'
}

export const GENDER: Gender[] = [
  { name: 'Male', value: 'M' },
  { name: 'Female', value: 'F' },
  { name: 'Unknown', value: 'U' }
]

export const defaultCountryId: number = 76
export const sortIcons: {
  sort: string
  sortAsc: string
  sortDesc: string
} = {
  sort: 'fa-solid:sort',
  sortAsc: 'fa-solid:sort-up',
  sortDesc: 'fa-solid:sort-down'
}

export const formatMoney = (value: number, currency = 'EUR', lang = 'nl-NL') =>
  new Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  }).format(value)

export const formatDate = (date: string | DateOption) => {
  const momentDate = moment(date, 'YYYY-MM-DD')
  if (momentDate.isValid()) {
    return momentDate.format('DD MMM, YYYY')
  }
  return ''
}

export const formatDateTime = (datetime: string) => {
  const momentDate = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('DD MMM, YYYY HH:mm:ss')
  }
  return ''
}

export const formatTime = (datetime: string | Date) => {
  const momentDate = moment(datetime, 'HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('hh:mm')
  }
  return ''
}

export const calculateProgress = (deadline: string): string => {
  const deadlineDate = moment(deadline, 'YYYY-MM-DD')
  const currentDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
  const daysDifference = deadlineDate.diff(currentDate, 'days')
  let backgroundColor: string
  let buttonText: string
  if (daysDifference > 5) {
    backgroundColor = 'bg-green-500'
    buttonText = 'On Time'
  } else if (daysDifference < 5 && daysDifference > 0) {
    backgroundColor = 'bg-yellow-500'
    buttonText = 'Warning'
  } else {
    buttonText = 'Overdue'
    backgroundColor = 'bg-red-500'
  }
  return `<button class="text-white text-xs px-3 py-1 rounded-full ${backgroundColor}"><b>${buttonText}</b></button>`
}

export const renderConcluded = (data: string): string =>
  data
    ? `<div class="text-center">
        <span class="inline-block px-2 py-1 text-white bg-green-500 rounded-lg">
          <i class="fas fa-check-circle"></i> Concluded
        </span>
      </div>`
    : `<div class="text-center">-</div>`
export const secondsToMinutes = (data: number): number => Math.floor(data / 60)

export const renderGender = (gender: string) => {
  if (gender === 'M') {
    return 'Male'
  } else if (gender === 'F') {
    return 'Female'
  } else {
    return 'Unknown'
  }
}

export const RELATIONSHIP_STATUSES: RelationshipStatus[] = [
  { value: 'single', name: 'Single' },
  { value: 'in_a_relationship', name: 'In a Relationship' },
  { value: 'married', name: 'Married' },
  { value: 'civil_partnership', name: 'Civil Partnership' },
  { value: 'cohabiting', name: 'Cohabiting' },
  { value: 'separated', name: 'Separated' },
  { value: 'divorced', name: 'Divorced' },
  { value: 'widowed', name: 'Widowed' },
  { value: 'engaged', name: 'Engaged' },
  { value: 'prefer_not_to_say', name: 'Prefer Not To Say' },
  { value: 'other', name: 'Other' }
]

export const getUserNameInitials = (name: string | undefined) => {
  if (!name) return 'U'
  const [firstName, lastName] = name.split(' ')
  return `${firstName ? firstName.charAt(0) : ''}${lastName ? lastName.charAt(0) : ''}`
}

export const badge = (value: string): string => {
  return `<span class="bg-blue-500 text-white py-1 px-2 rounded">${value}</span>  `
}

export const formatDateTimeToTime = (datetime: string | Date): string => {
  const momentDate = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('HH:mm A')
  }
  return ''
}

export const link = (link: string): string => {
  return `
      <router-link
        v-if="link"
        :to="${link}"
      >
        <Icon icon="mdi:link" class="text-xl mr-2" />
        <span class="text-sm">view</span>
      </router-link>
  `
}

export const renderTaskListStatus = (status: string): string => {
  if (status === TASK_STATUS.COMPLETED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-green-500">Completed</span>`
  } else if (status === TASK_STATUS.PENDING) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-yellow-500">Pending</span>`
  } else if (status === TASK_STATUS.CANCELLED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-red-500">Cancelled</span>`
  } else if (status === TASK_STATUS.NO_SHOW) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-500">No Show</span>`
  } else if (status === TASK_STATUS.HALT) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Halt</span>`
  } else {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Unknown</span>`
  }
}

export const renderTaskType = (questionnaireType: string): string => {
  if (questionnaireType === 'App\\Models\\Form\\Form') {
    return `Form`
  } else if (questionnaireType === 'App\\Models\\Surveys\\SurveyQuestion') {
    return `Survey`
  } else if (questionnaireType === 'App\\Models\\Template') {
    // Todo: breakdown the template type
    return `Template`
  }
  return ''
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const renderActionListStatus = (status: string): string => {
  if (status === ACTION_LIST_STATUS.COMPLETED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-green-500">Completed</span>`
  } else if (status === ACTION_LIST_STATUS.PENDING) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-yellow-500">Pending</span>`
  } else if (status === ACTION_LIST_STATUS.CANCELLED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-red-500">Cancelled</span>`
  } else if (status === ACTION_LIST_STATUS.NO_SHOW) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-purple-500">No Show</span>`
  } else if (status === ACTION_LIST_STATUS.DELETED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Halt</span>`
  } else if (status === ACTION_LIST_STATUS.EXECUTED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-green-800">Executed</span>`
  } else if (status === ACTION_LIST_STATUS.INVOICED) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-blue-800">Invoiced</span>`
  } else if (status === ACTION_LIST_STATUS.PAID) {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-teal-800">Paid</span>`
  } else {
    return `<span class="rounded-full text-white text-xs px-3 py-1 bg-gray-800">Unknown</span>`
  }
}

export const languageOptions = [
  { name: 'English', value: LanguageEnum.EN },
  { name: 'Dutch', value: LanguageEnum.NL }
]

export const activityTypeList = [
  { id: 'person', name: 'Person' },
  { id: 'organisation', name: 'Organisation' }
]

export const activityProgressList = [
  { id: 'on_time', name: 'On Time' },
  { id: 'warning', name: 'Warning' },
  { id: 'overdue', name: 'Overdue' }
]

export const activityAppointmentTypes = [
  { value: 'on_site', name: 'On Site' },
  { value: 'phone_call', name: 'Phone Call' },
  { value: 'video_call', name: 'Video Call' }
]

export const TASK_STATUS_TYPES: TaskStatus[] = [
  { value: 'pending', name: 'Pending' },
  { value: 'completed', name: 'Completed' },
  { value: 'cancelled', name: 'Cancelled' },
  { value: 'halt', name: 'Halt' },
  { value: 'no_show', name: 'NoShow' }
]

export const renderRecordType = (recordType: string): string => {
  return recordType
}
