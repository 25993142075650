import { decode, encode } from 'base-64'
import Cookies from 'js-cookie'

function getAuthCookie(): string | null {
  const cookie = Cookies.get('auth_token')
  if (!cookie) {
    return null
  }
  try {
    return JSON.parse(decode(cookie))
  } catch {
    return null
  }
}

function setAuthCookie(value: string) {
  Cookies.set('auth_token', encode(JSON.stringify(value)))
}

function removeAuthCookie() {
  Cookies.remove('auth_token')
}

type getAuthCookie = () => string | null
type setAuthCookie = (value: string) => void
type removeAuthCookie = () => void

export { getAuthCookie, setAuthCookie, removeAuthCookie }
