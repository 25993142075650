import { useToast } from 'vue-toastification'
import axiosInstance from '~/utils/common-http'
import { useErrorStore } from '~/stores/ErrorStore'
import { useAuthStore } from '~/stores/AuthStore'
import type { App } from 'vue'
import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import type { ErrorMessage, ValidationErrors } from '~/ts/globals'
import { getAuthCookie } from '~/utils/auth-cookie'

export default {
  install: (app: App) => {
    app.config.globalProperties.$http = axiosInstance
    const $http = app.config.globalProperties.$http

    app.provide('$http', $http)

    const errorStore = useErrorStore()
    const { addApiErrors, addFormErrors, resetErrors } = errorStore

    const handleResponse = (response: AxiosResponse) => {
      resetErrors()
      return response
    }

    const handleError = (error: AxiosError) => {
      const apiError = error.response?.data as ErrorMessage

      const toast = useToast()

      if (error.code === 'ERR_NETWORK') {
        toast.error('Server is not responding or please check your internet connection.')
      }
      toast.error(apiError.message)

      addApiErrors(apiError)

      if (error.response?.status === 401) {
        const { resetAuthUser } = useAuthStore()
        resetAuthUser()
      }

      if (error.response?.status === 422) {
        const validationErrors = error.response?.data as ValidationErrors
        if (validationErrors.errors) {
          addFormErrors(validationErrors.errors)
        }
      }

      return Promise.reject(error)
    }

    $http.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const token = getAuthCookie()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    })

    $http.interceptors.response.use(handleResponse, handleError)
  }
}

export const $http = axiosInstance
